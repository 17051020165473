import React from "react";
import Link from "next/link";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {IProps} from "../../types/homePageTypes";

const HotelDropdownLanguage = dynamic(() => import("./Hotel_DropdownLanguage"))

function TopBar() {
    const phone = useSelector((state: IState) => state.general.coreConfigs.general_info_general_phone?.value);
    const linksList = (
        <div className="topBar__item">
            {
                phone
                    ? (
                        <Link href={`tel:${phone}`}>
                            <a>
                                {phone}
                            </a>
                        </Link>
                    )
                    : (
                        ""
                    )
            }
        </div>
    );

    return (
        <div className="topBar">
            <div className="topBar__container container">
                <div className="topBar__row">
                    {linksList}
                    <div className="language_bar">
                        <div className="language-adjust">
                            <HotelDropdownLanguage/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
